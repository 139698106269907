<template>
  <div
    style="
      height: 100%;
      background: linear-gradient(
        to right,
        #ffffff 0%,
        #ffffff 50%,
        #f2f2f2 50%,
        #f2f2f2 100%
      );
      border-top: solid 1px #dbdbdb;
    "
  >
    <v-container
      class="py-0"
      container--fluid
      grid-list-lg
      style="height: 100%"
    >
      <v-row style="height: 100%">
        <v-col md="2" v-if="$vuetify.breakpoint.mdAndUp">
          <v-navigation-drawer
            permanent
            floating
            class="ma-0 pa-0"
            style="background-color: white"
          >
            <v-list dense class="px-0 py-3 mt-1">
              <v-list-item
                v-for="item in [
                  {
                    title: '새 캠페인 만들기',
                    path: { name: 'Regcam', params: { channel: 'naver' } },
                    icon: 'mdi-creation',
                    class: 'item',
                    hide: false,
                  },
                ]"
                active-class="rouge--text"
                class="item--matching"
                :class="item.class"
                :key="item.title"
                :to="item.path"
              >
                <v-list-item-icon class="mr-4 ml-2">
                  <v-icon dense style="height: 32px">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="body-1">
                  <v-list-item-title style="font-weight: 400">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list
              v-for="channel in [
                { name: 'naver', title: '블로거', list: listNaver },
                { name: 'youtube', title: '유튜버', list: listYoutube },
                { name: 'tiktok', title: '틱톡', list: listTiktok },
              ]"
              :key="channel.name"
              dense
              class="px-0 pt-2"
            >
              <v-row
                v-for="[title, path, icon, cls, group] in channel.list"
                :key="title"
              >
                <v-list-item
                  v-if="!group"
                  active-class="rouge--text"
                  class="item--matching"
                  :class="cls"
                  @click="menuClicked(path)"
                >
                  <v-list-item-icon class="mr-4 ml-2">
                    <naverIcon v-if="icon === 'naver'" class="mt-2" />
                    <youtubeIcon v-else-if="icon === 'youtube'" class="mt-2" />
                    <v-icon v-else dense style="height: 32px">{{
                      icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="body-1">
                    <v-list-item-title style="font-weight: 400">{{
                      title
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group v-else flat value="true">
                  <template v-slot:activator>
                    <v-list-item
                      :to="{ path: path }"
                      class="px-0 item--matching"
                      flat
                    >
                      <v-list-item-icon class="mr-4 ml-2"> </v-list-item-icon>
                      <v-list-item-title style="font-weight: 400">{{
                        title
                      }}</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item
                    class="py-1"
                    v-for="(campaign, i) in campaigns.filter(
                      (campaign) => campaign[channel.name]
                    )"
                    :key="channel.name + i"
                    :to="{
                      name: 'CampaignDetail',
                      params: {
                        index: _.findIndex(campaigns, { id: campaign.id }),
                        campaign: campaign,
                      },
                    }"
                  >
                    <v-list-item-icon class="mr-4 ml-6"> </v-list-item-icon>
                    <v-list-item-title
                      v-text="campaign.name"
                      style="
                        font-weight: 400;
                        white-space: normal;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                      "
                    ></v-list-item-title>
                  </v-list-item>
                  <!--                  <v-list-item class="py-1">-->
                  <!--                    <v-list-item-icon class="mr-4 ml-6">-->
                  <!--                    </v-list-item-icon>-->
                  <!--                    <v-list-item-action class="ma-0">-->
                  <!--                      <v-btn class="ma-0 px-2 py-4 addcam-btn" style="font-weight:400;" rounded outlined>-->
                  <!--                        <router-link :to="{name: 'Regcam', params: {channel: channel.name}}"><v-icon dense>mdi-plus-circle-outline</v-icon> 새 캠페인 추가</router-link>-->
                  <!--                      </v-btn>-->
                  <!--                    </v-list-item-action>-->
                  <!--                  </v-list-item>-->
                </v-list-group>
                <v-divider
                  class="mt-4"
                  v-if="
                    (channel.name === 'naver') | (channel.name === 'youtube')
                  "
                ></v-divider>
              </v-row>
            </v-list>
            <v-divider class="mt-2"></v-divider>
            <v-list dense class="px-0 py-4">
              <v-list-item
                v-for="item in list2"
                active-class="rouge--text"
                class="item--matching"
                :class="item.class"
                :key="item.title"
                :to="item.path"
              >
                <v-list-item-icon class="mr-4 ml-2">
                  <v-icon dense style="height: 32px">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="body-1">
                  <v-list-item-title style="font-weight: 400">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : ''"
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'padding-bottom: 200px !important'
              : ''
          "
          style="background-color: #f2f2f2"
        >
          <v-col class="ml-1 pb-4" v-if="$vuetify.breakpoint.mdAndUp">
            <v-row>
              <v-col md="9">
                <v-row>
                  <v-col cols="12" md="8">
                    <Header></Header>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <router-view
                  :key="
                    $route.path !== '/campaign/search' &&
                    $route.path !== '/campaign/searchyoutube'
                      ? $route.path
                      : routeKey
                  "
                ></router-view>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import firebase from '@/config/firebase'
import "@firebase/firestore";

import userMixin from "@/components/shared/userMixin";
import campaignMixin from "@/components/shared/campaignMixin";
import Header from "@/components/campaign/Header";

import { gEventBus } from "../../main";

export default {
  name: "Campaign",
  mixins: [userMixin, campaignMixin],
  components: {
    Header,
    naverIcon: () => import("@/assets/img/campaign/naver_c.svg"),
    youtubeIcon: () => import("@/assets/img/campaign/youtube_c.svg"),
  },
  data() {
    return {
      listNaver: [
        ["블로거 검색", "/campaign/search", "naver", "item", false],
        ["직접 제안하기", "/campaign/proposition", "", "item", false],
        ["자동 제안하기", "/campaign/autoproposition", "", "item", false],
        ["블로거 캠페인 목록", "/campaign/list", "", "item", true],
      ],
      listYoutube: [
        ["유튜버 검색", "/campaign/searchyoutube", "youtube", "item", false],
        ["직접 제안하기", "/campaign/propositionyoutube", "", "item", false],
        ["유튜브 캠페인 목록", "/campaign/listyoutube", "", "item", true],
      ],
      listTiktok: [
        ["틱톡커 검색", "/campaign/searchtiktok", "tiktok", "item", false],
      ],
      list2: [
        {
          title: "충전금액 관리",
          path: "/campaign/charge",
          icon: "mdi-credit-card",
          class: "item",
          hide: false,
        },
        {
          title: "담당자 정보수정",
          path: "/campaign/editinfo",
          icon: "mdi-account-supervisor-circle",
          class: "item",
          hide: false,
        },
        {
          title: "로그아웃",
          path: "/logout",
          icon: "",
          class: "item",
          hide: false,
        },
      ],
      routeKey: 0,
    };
  },
  computed: {
    items() {
      const items =
        JSON.parse(JSON.stringify(this.$store.getters.campaigns)) || [];
      return items;
    },
  },
  watch: {
    $route(to) {
      this.setListClass(to.path);
    },
    // campaigns () {
    //   if (this._.isEmpty(this.campaigns)) {
    //     this.$router.push({name: 'Regcam'});
    //   }
    //
    //   if (!this._.isEmpty(this.campaigns) && !this._.isEmpty(this.user)) {
    //     this.campaigns.forEach((campaign) => {
    //       firebase.app.firestore().collection('campaign').doc(company.id + '').onSnapshot((snapshot) => {
    //         let campaign = snapshot.data();
    //
    //         if (this.$moment.tz('Asia/Seoul').diff(campaign.updatedAt ? company.updatedAt.toDate() : null, 'seconds') < 5) {
    //           location.reload();
    //         }
    //       });
    //     });
    //   }
    // }
  },
  methods: {
    setListClass(path) {
      this.listNaver.forEach((item, idx) => {
        if (item[1] === path) {
          if (idx < this.listNaver.length) {
            this.listNaver[idx][3] =
              "item item_active rouge--text v-list-item--active";
          }
        } else {
          if (idx < this.listNaver.length) {
            this.listNaver[idx][3] = "item";
          }
        }
      });
      this.list2.forEach((item, idx) => {
        if (item.path === path) {
          if (idx < this.list2.length - 1) {
            this.list2[idx + 1].class = "item item_active";
          }
        } else {
          if (idx < this.list2.length - 1) {
            this.list2[idx + 1].class = "item";
          }
        }
      });
    },
    menuClicked(path) {
      this.$router.push(path);
      if (path === "/campaign/search" || path === "/campaign/searchyoutube") {
        gEventBus.$emit("refreshSearch", path);
        this.routeKey += 1; // to refresh path
        // this.$router.go();
      }
    },
    // onLogout() {
    //   this.$store.dispatch('logout');
    //   this.$router.push('/')
    // }
  },
  async created() {
    this.list2 = this.list2.filter((item) => {
      return !item.hide;
    });

    this.setListClass(this.$route.path);
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/custom";

::v-deep .container {
  max-width: 1440px;
}

@media (min-width: 960px) {
  ::v-deep .container {
    width: 1440px;
  }
}

::v-deep .item.v-list-item--active:before {
  background-color: #ffffff;
}

::v-deep .item.v-list-item {
  border-bottom: none;
  box-sizing: border-box;
  color: #bd1328;
  color: var(--rouge-color);
}

::v-deep .item:last-child.v-list-item {
  box-sizing: border-box;
  color: #bd1328;
  color: var(--rouge-color);
}

::v-deep .item.item_active.v-list-item {
  border-top: none;
}

::v-deep .item.v-list-item--active,
::v-deep .item:last-child.v-list-item--active {
  background-color: #fff;
}

::v-deep .item--matching .v-list-item__title {
  font-size: 14px;
}

::v-deep .content-page {
  border: solid 1px #979797;
  border: solid 1px var(--border-color);
}

::v-deep .v-text-field--outlined.v-text-field--single-line .v-input__slot {
  min-height: 44px;
}

::v-deep .v-select__selections span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 170px;
}

.align-select {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.addcam-btn {
  font-size: 13px;
  line-height: 20px;
  border: solid 1px var(--primary-color);
}

.item--matching {
  height: 48px;
  margin-bottom: 0;
}
</style>
