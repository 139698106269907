var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$route.path === '/campaign/search')?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("블로거 검색")]),_vm._m(0)]):(_vm.$route.path === '/campaign/proposition')?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("제안하기")]),_vm._m(1)]):(_vm.$route.path === '/campaign/autoproposition')?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("자동 제안하기")]),_vm._m(2)]):(_vm.$route.path === '/campaign/charge')?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("충전금액 관리")]),_vm._m(3)]):(_vm.$route.path === '/campaign/list')?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("캠페인 목록")]),_c('p',{staticClass:"content"},[_vm._v(" 현재 진행중인 캠페인의 성과를 확인해보세요. ")])]):(_vm.$route.path.indexOf('/campaign/detail') > -1)?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("캠페인 관리")]),_vm._m(4)]):(_vm.$route.path === '/campaign/manage')?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("캠페인 관리")]),_vm._m(5)]):(_vm.$route.path === '/campaign/editinfo')?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v("담당자 정보수정")]),_vm._m(6)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content"},[_vm._v(" 블로그 상위노출을 원하는 키워드를 검색하고,"),_c('br'),_vm._v(" 키워드에 맞는 블로거를 골라보세요. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content"},[_vm._v(" 제안하기 목록으로 항목 이동 후 해당 인플루언서에게"),_c('br'),_vm._v(" 제안을 보낼 수 있습니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content"},[_vm._v(" 원하는 조건과 예산을 설정하면, 알맞은 인플루언서에게"),_c('br'),_vm._v(" 자동으로 제안이 발송됩니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content"},[_vm._v(" 제안금액을 충전하고"),_c('br'),_vm._v(" 광고 제안을 시작해보세요. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content"},[_vm._v(" 캠페인과 내용을 수정하고,"),_c('br'),_vm._v(" 이용 내역과 결과를 열람할 수 있습니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content"},[_vm._v(" 캠페인과 내용을 수정하고,"),_c('br'),_vm._v(" 이용 내역과 결과를 열람할 수 있습니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content"},[_vm._v(" 광고 및 이용과 관련하여 슈퍼차트에서"),_c('br'),_vm._v(" 연락을 취할 담당자분의 정보를 수정하는 페이지입니다. ")])
}]

export { render, staticRenderFns }