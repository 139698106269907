<template>
  <div>
    <div v-if="$route.path === '/campaign/search'">
      <h2 class="headline">블로거 검색</h2>
      <p class="content">
        블로그 상위노출을 원하는 키워드를 검색하고,<br>
        키워드에 맞는 블로거를 골라보세요.
      </p>
    </div>
    <div v-else-if="$route.path === '/campaign/proposition'">
      <h2 class="headline">제안하기</h2>
      <p class="content">
        제안하기 목록으로 항목 이동 후 해당 인플루언서에게<br>
        제안을 보낼 수 있습니다.
      </p>
    </div>
    <div v-else-if="$route.path === '/campaign/autoproposition'">
      <h2 class="headline">자동 제안하기</h2>
      <p class="content">
        원하는 조건과 예산을 설정하면, 알맞은 인플루언서에게<br>
        자동으로 제안이 발송됩니다.
      </p>
    </div>
    <div v-else-if="$route.path === '/campaign/charge'">
      <h2 class="headline">충전금액 관리</h2>
      <p class="content">
        제안금액을 충전하고<br>
        광고 제안을 시작해보세요.
      </p>
    </div>
    <div v-else-if="$route.path === '/campaign/list'">
      <h2 class="headline">캠페인 목록</h2>
      <p class="content">
        현재 진행중인 캠페인의 성과를 확인해보세요.
      </p>
    </div>
    <div v-else-if="$route.path.indexOf('/campaign/detail') > -1">
      <h2 class="headline">캠페인 관리</h2>
      <p class="content">
        캠페인과 내용을 수정하고,<br>
        이용 내역과 결과를 열람할 수 있습니다.
      </p>
    </div>
    <div v-else-if="$route.path === '/campaign/manage'">
      <h2 class="headline">캠페인 관리</h2>
      <p class="content">
        캠페인과 내용을 수정하고,<br>
        이용 내역과 결과를 열람할 수 있습니다.
      </p>
    </div>
    <div v-else-if="$route.path === '/campaign/editinfo'">
      <h2 class="headline">담당자 정보수정</h2>
      <p class="content">
        광고 및 이용과 관련하여 슈퍼차트에서<br>
        연락을 취할 담당자분의 정보를 수정하는 페이지입니다.
      </p>
    </div>
  </div>
</template>

<script>
  import userMixin from '../shared/userMixin'
  import companyMixin from '../shared/companyMixin'

  export default {
    name: 'Header',
    mixins: [userMixin, companyMixin]
  }
</script>

<style scoped lang="scss">
  .headline {
    font-weight: 700;
    margin-bottom: 6px;
  }

  .content {
    line-height: 24px;
  }
</style>
