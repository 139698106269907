var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","background":"linear-gradient(","border-top":"solid 1px #dbdbdb"}},[_c('v-container',{staticClass:"py-0",staticStyle:{"height":"100%"},attrs:{"container--fluid":"","grid-list-lg":""}},[_c('v-row',{staticStyle:{"height":"100%"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"md":"2"}},[_c('v-navigation-drawer',{staticClass:"ma-0 pa-0",staticStyle:{"background-color":"white"},attrs:{"permanent":"","floating":""}},[_c('v-list',{staticClass:"px-0 py-3 mt-1",attrs:{"dense":""}},_vm._l(([
                {
                  title: '새 캠페인 만들기',
                  path: { name: 'Regcam', params: { channel: 'naver' } },
                  icon: 'mdi-creation',
                  class: 'item',
                  hide: false,
                },
              ]),function(item){return _c('v-list-item',{key:item.title,staticClass:"item--matching",class:item.class,attrs:{"active-class":"rouge--text","to":item.path}},[_c('v-list-item-icon',{staticClass:"mr-4 ml-2"},[_c('v-icon',{staticStyle:{"height":"32px"},attrs:{"dense":""}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{staticClass:"body-1"},[_c('v-list-item-title',{staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('v-divider'),_vm._l(([
              { name: 'naver', title: '블로거', list: _vm.listNaver },
              { name: 'youtube', title: '유튜버', list: _vm.listYoutube },
              { name: 'tiktok', title: '틱톡', list: _vm.listTiktok },
            ]),function(channel){return _c('v-list',{key:channel.name,staticClass:"px-0 pt-2",attrs:{"dense":""}},_vm._l((channel.list),function([title, path, icon, cls, group]){return _c('v-row',{key:title},[(!group)?_c('v-list-item',{staticClass:"item--matching",class:cls,attrs:{"active-class":"rouge--text"},on:{"click":function($event){return _vm.menuClicked(path)}}},[_c('v-list-item-icon',{staticClass:"mr-4 ml-2"},[(icon === 'naver')?_c('naverIcon',{staticClass:"mt-2"}):(icon === 'youtube')?_c('youtubeIcon',{staticClass:"mt-2"}):_c('v-icon',{staticStyle:{"height":"32px"},attrs:{"dense":""}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',{staticClass:"body-1"},[_c('v-list-item-title',{staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(title))])],1)],1):_c('v-list-group',{attrs:{"flat":"","value":"true"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"px-0 item--matching",attrs:{"to":{ path: path },"flat":""}},[_c('v-list-item-icon',{staticClass:"mr-4 ml-2"}),_c('v-list-item-title',{staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(title))])],1)]},proxy:true}],null,true)},_vm._l((_vm.campaigns.filter(
                    (campaign) => campaign[channel.name]
                  )),function(campaign,i){return _c('v-list-item',{key:channel.name + i,staticClass:"py-1",attrs:{"to":{
                    name: 'CampaignDetail',
                    params: {
                      index: _vm._.findIndex(_vm.campaigns, { id: campaign.id }),
                      campaign: campaign,
                    },
                  }}},[_c('v-list-item-icon',{staticClass:"mr-4 ml-6"}),_c('v-list-item-title',{staticStyle:{"font-weight":"400","white-space":"normal","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","display":"-webkit-box"},domProps:{"textContent":_vm._s(campaign.name)}})],1)}),1),(
                  (channel.name === 'naver') | (channel.name === 'youtube')
                )?_c('v-divider',{staticClass:"mt-4"}):_vm._e()],1)}),1)}),_c('v-divider',{staticClass:"mt-2"}),_c('v-list',{staticClass:"px-0 py-4",attrs:{"dense":""}},_vm._l((_vm.list2),function(item){return _c('v-list-item',{key:item.title,staticClass:"item--matching",class:item.class,attrs:{"active-class":"rouge--text","to":item.path}},[_c('v-list-item-icon',{staticClass:"mr-4 ml-2"},[_c('v-icon',{staticStyle:{"height":"32px"},attrs:{"dense":""}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{staticClass:"body-1"},[_c('v-list-item-title',{staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],2)],1):_vm._e(),_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pa-6' : '',staticStyle:{"background-color":"#f2f2f2"},style:(_vm.$vuetify.breakpoint.mdAndUp
            ? 'padding-bottom: 200px !important'
            : '')},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"ml-1 pb-4"},[_c('v-row',[_c('v-col',{attrs:{"md":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('Header')],1)],1)],1)],1)],1):_vm._e(),_c('v-col',[_c('v-row',[_c('v-col',[_c('router-view',{key:_vm.$route.path !== '/campaign/search' &&
                  _vm.$route.path !== '/campaign/searchyoutube'
                    ? _vm.$route.path
                    : _vm.routeKey})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }